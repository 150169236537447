import { pairwise, startWith } from "rxjs/operators";
import { AbstractControl } from '@angular/forms';
import { ignoreValue } from '@k2/common/filters';

/**
 * Returns true if every part of `query` is included in `target`.
 */
export function includesParts(target: string, query: string): boolean {
  if (query == null) return true;
  const normalizedTarget = target.toLowerCase();
  const parts = query.toLowerCase().split(' ');

  return parts.every(part => normalizedTarget.includes(part));
}

/**
 * Returns true if `target` contains elements of `query` or if `query` contains `all` value.
 */
export function includesValues(target: any[], query: any[]): boolean {
  if (query.includes(ignoreValue)) return true;
  return query.every(queryEl => target.includes(queryEl));
}

/**
 * Prevents the selection of both 'all' and other individual values at the same time
 */
export function handleMultiSelectValueChanges(control: AbstractControl, allOption: string) {
  control.valueChanges
    .pipe(
      startWith([allOption]),
      pairwise()
    )
    .subscribe(([prev, next]) => {
      if (next?.length > 1 && prev.includes(allOption) && next.includes(allOption)) {
        control.setValue(removeOptionAllFromArray(next, allOption));
      } else if (next?.length > 1 && !prev.includes(allOption) && next.includes(allOption)) {
        control.setValue([allOption]);
      }
    });
}

export function removeOptionAllFromArray(array: any, allOption: string) {
  return array.filter(item => item !== allOption);
}

export function getControlName(c: AbstractControl): string | null {
  const formGroup = c.parent.controls;
  return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
}
