import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterOption, ignoreValue } from '@k2/common/filters';
import { getControlName } from '@k2/common/filters/utils';


@Component({
  selector: 'multi-select-filter',
  templateUrl: 'multi-select-filter.component.html',
  styleUrls: ['multi-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectFilterComponent {
  private _control: UntypedFormControl;

  get control(): UntypedFormControl {
    return this._control;
  }
  @Input() set control(value: UntypedFormControl) {
    this._control = value;
    this.matSelectId = getControlName(value);
  }
  @Input() options: FilterOption[];
  @Input() placeholder = 'all';
  @Input() displayPlaceholder = true;

  matSelectId: string | null = null;

  get ignoreValue() {
    return ignoreValue;
  }
}
