<user-ingot-base [user]="user" [link]="user | k2UserLink">
  <div class="d-flex flex-column">
    <span *ngIf="numberOfActiveAssignments === 1">1 active assignment</span>
    <span *ngIf="numberOfActiveAssignments !== 1">
    {{ numberOfActiveAssignments }} active assignments
  </span>
    <span *ngIf="numberOfOngoingAssignments === 1">1 ongoing assignment</span>
    <span *ngIf="numberOfOngoingAssignments !== 1">
    {{ numberOfOngoingAssignments }} ongoing assignments
  </span>
  </div>
</user-ingot-base>
