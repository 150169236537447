import { clientSchema } from '@k2/common/clients-state/normalizer-schemas';
import { getDenormalized, getDenormalizedObject, getOneDenormalized } from '@k2/common/entities-state/selectors';
import { Client, CurrentK2UserAssignments, K2User } from '@k2/common/entities-state/types';
import {
  Actions,
  ADD_AVAILABLE_DATA,
  STORE_ALL_K2_USERS,
  STORE_AVAILABLE_DATA,
  STORE_K2_USER,
  STORE_K2_USER_CLIENTS,
  STORE_K2_USER_CURRENT_ASSIGNMENTS,
  STORE_K2_USER_TEAM
} from '@k2/common/k2-users-state/actions';
import { k2UserCurrentAssignmentsSchema } from '@k2/common/k2-users-state/normalizer-schemas';
import {
  AvailableDataState,
  ClientsState,
  CurrentAssignmentsState,
  ProfilesState,
  RootState,
  State,
  TeamsState
} from '@k2/common/k2-users-state/state';
import { tasksReducer } from '@k2/common/k2-users-state/tasks/reducers';
import { k2UserSchema } from '@k2/common/users-state/normalizer-schemas';
import { combineReducers, Selector } from '@ngrx/store';
import { identity, pipe } from 'ramda';
import { createSelector } from 'reselect';
import { map } from 'rxjs/operators';

export { getK2UserTeam } from './common';

export const featureReducer = combineReducers<State>({
  all: allReducer,
  profiles: profilesReducer,
  teams: teamsReducer,
  clients: clientsReducer,
  currentAssignments: currentAssignmentsReducer,
  tasks: tasksReducer,
  availableData: availableDataReducer
});

function allReducer(state: number[], action: Actions): number[] {
  if (action.type === STORE_ALL_K2_USERS) {
    return action.ids;
  }
  return state;
}

function availableDataReducer(state: AvailableDataState, action: Actions): AvailableDataState {
  if (action.type === STORE_AVAILABLE_DATA) {
    return {
      clients: action.clients,
      assignments: action.assignments,
    };
  } else if (action.type === ADD_AVAILABLE_DATA) {
    return {
      clients: state?.clients ? [...state.clients, ...action.clients] : null,
      assignments: state?.assignments ? [...state.assignments, ...action.assignments] : null
    };
  }
  return state;
}

export const getAllK2Users: Selector<RootState, K2User[]> = createSelector(
  [(state: RootState) => state.k2Users.all, identity],
  (ids, state) => {
    if (ids != null) return getDenormalized(k2UserSchema, ids)(state);
  }
);

function profilesReducer(state: ProfilesState = {}, action: Actions): ProfilesState {
  if (action.type === STORE_K2_USER) {
    return {
      ...state,
      [action.id]: { loaded: true }
    };
  }
  return state;
}

export function getK2User(id: number): Selector<RootState, K2User> {
  return createSelector(
    [(state: RootState) => state.k2Users.profiles[id], identity],
    (userState, state: RootState) => {
      if (userState != null) return getOneDenormalized(k2UserSchema, id)(state);
    }
  );
}

function teamsReducer(state: TeamsState = {}, action: Actions): TeamsState {
  if (action.type === STORE_K2_USER_TEAM) {
    return {
      ...state,
      [action.userId]: action.ids
    };
  }
  return state;
}

function clientsReducer(state: ClientsState = {}, action: Actions): ClientsState {
  if (action.type === STORE_K2_USER_CLIENTS) {
    return {
      ...state,
      [action.userId]: action.ids
    };
  }
  return state;
}

export function getK2UserClients(userId: number): Selector<RootState, Client[]> {
  return createSelector(
    [(state: RootState) => state.k2Users.clients[userId], identity],
    (ids, state) => {
      if (ids != null) return getDenormalized(clientSchema, ids)(state);
    }
  );
}

function currentAssignmentsReducer(
  state: CurrentAssignmentsState = {},
  action: Actions
): CurrentAssignmentsState {
  if (action.type === STORE_K2_USER_CURRENT_ASSIGNMENTS) {
    return {
      ...state,
      [action.userId]: action.current
    };
  }
  return state;
}

export function getK2UserCurrentAssignments(
  userId: number
): Selector<RootState, CurrentK2UserAssignments> {
  return createSelector(
    [(state: RootState) => state.k2Users.currentAssignments[userId], identity],
    (normalized, state: RootState) => {
      if (normalized != null)
        return getDenormalizedObject(k2UserCurrentAssignmentsSchema, normalized)(state);
    }
  );
}

export const getAvailableClientsIsEmpty = pipe(map((rootState: RootState): boolean =>
  rootState.k2Users.availableData && rootState.k2Users.availableData.clients && !rootState.k2Users.availableData.clients.length));

export const getAvailableData = pipe(map((rootState: RootState): AvailableDataState =>
  rootState.k2Users.availableData));
