import { Injectable } from '@angular/core';
import { ApiClient } from '@k2/common/backend/api-client';
import { AssigneeEndpoint } from '@k2/common/backend/endpoints/assignee-endpoint';
import { AssignmentAnalysisEndpoint } from '@k2/common/backend/endpoints/assignment-analysis-endpoint';
import { AssignmentAssigneeEndpoint } from '@k2/common/backend/endpoints/assignment-assignee-endpoint';
import { AssignmentPartyEndpoint } from '@k2/common/backend/endpoints/assignment-party-endpoint';
import { AssignmentHhgEndpoint } from '@k2/common/backend/endpoints/assignment-hhg-endpoint';
import { AssignmentServicesEndpoint } from '@k2/common/backend/endpoints/assignment-services-endpoint';
import { AssignmentsEndpoint } from '@k2/common/backend/endpoints/assignments-endpoint';
import { AutocompleteEndpoint } from '@k2/common/backend/endpoints/autocomplete-endpoint';
import { ClientAssignmentEndpoint } from '@k2/common/backend/endpoints/client-assignment-endpoint';
import { ClientEstimateEndpoint } from '@k2/common/backend/endpoints/client-estimate-endpoint';
import { ClientAssignmentsEndpoint } from '@k2/common/backend/endpoints/client-assignments-endpoint';
import { ClientsEndpoint } from '@k2/common/backend/endpoints/clients-endpoint';
import { ConfigEndpoint } from '@k2/common/backend/endpoints/config-endpoint';
import { CountriesEndpoint } from '@k2/common/backend/endpoints/countries-endpoint';
import { DocumentsEndpoint } from '@k2/common/backend/endpoints/documents-endpoint';
import { EventsCalendarEndpoint } from '@k2/common/backend/endpoints/events-calendar-endpoint';
import { FeedbackEndpoint } from '@k2/common/backend/endpoints/feedback-endpoint';
import { HrEndpoint } from '@k2/common/backend/endpoints/hr-endpoint';
import { OfficesEndpoint } from '@k2/common/backend/endpoints/offices-endpoint';
import { PartnersEndpoint } from '@k2/common/backend/endpoints/partners-endpoint';
import { ReportsEndpoint } from '@k2/common/backend/endpoints/reports-endpoint';
import { SearchEndpoint } from '@k2/common/backend/endpoints/search-endpoint';
import { SessionsEndpoint } from '@k2/common/backend/endpoints/sessions-endpoint';
import { StaffEndpoint } from '@k2/common/backend/endpoints/staff-endpoint';
import { StatsEndpoint } from '@k2/common/backend/endpoints/stats-endpoint';
import { TasksEndpoint } from '@k2/common/backend/endpoints/tasks-endpoint';
import { UsersEndpoint } from '@k2/common/backend/endpoints/users-endpoint';
import { getSessionUserId } from '@k2/common/sessions-state/reducers';
import { AppStore } from '@k2/common/state/services/app-store';
import { select } from '@ngrx/store';
import { AgreeTermsEndpoint } from '@k2/common/backend/endpoints/agree-terms-endpoint';
import { AssignmentRealEstateEndpoint } from '@k2/common/backend/endpoints/assignment-real-estate-endpoint';
import {ImmigrationEndpoint} from "@k2/common/backend/endpoints/immigration-endpoint";

@Injectable({ providedIn: 'root' })
export class Backend {
  readonly reports: ReportsEndpoint;
  readonly hr: HrEndpoint;
  readonly clientAssignment: ClientAssignmentEndpoint;
  readonly clientEstimate: ClientEstimateEndpoint;
  readonly clientAssignments: ClientAssignmentsEndpoint;
  readonly services: AssignmentServicesEndpoint;
  readonly clients: ClientsEndpoint;
  readonly users: UsersEndpoint;
  readonly staff: StaffEndpoint;
  readonly documents: DocumentsEndpoint;
  readonly offices: OfficesEndpoint;
  readonly sessions: SessionsEndpoint;
  readonly tasks: TasksEndpoint;
  readonly config: ConfigEndpoint;
  readonly partners: PartnersEndpoint;
  readonly stats: StatsEndpoint;
  readonly assignments: AssignmentsEndpoint;
  readonly feedback: FeedbackEndpoint;
  readonly autocomplete: AutocompleteEndpoint;
  readonly search: SearchEndpoint;
  readonly countries: CountriesEndpoint;
  readonly hhg: AssignmentHhgEndpoint;
  readonly assignee: AssigneeEndpoint;
  readonly assignmentAnalysis: AssignmentAnalysisEndpoint;
  readonly assignmentAssignee: AssignmentAssigneeEndpoint;
  readonly assignmentParty: AssignmentPartyEndpoint;
  readonly eventsCalendar: EventsCalendarEndpoint;
  readonly agreeTerms: AgreeTermsEndpoint;
  readonly realEstate: AssignmentRealEstateEndpoint;
  readonly visaImmigration: ImmigrationEndpoint;

  constructor(store: AppStore<any>, readonly api: ApiClient) {
    const currentUserId = store.pipe(select(getSessionUserId));
    this.reports = new ReportsEndpoint(this.api);
    this.hr = new HrEndpoint(this.api);
    this.clientAssignment = new ClientAssignmentEndpoint(this.api);
    this.clientEstimate = new ClientEstimateEndpoint(this.api);
    this.clientAssignments = new ClientAssignmentsEndpoint(this.api);
    this.services = new AssignmentServicesEndpoint(this.api);
    this.clients = new ClientsEndpoint(this.api);
    this.users = new UsersEndpoint(this.api);
    this.staff = new StaffEndpoint(this.api);
    this.documents = new DocumentsEndpoint(this.api);
    this.offices = new OfficesEndpoint(this.api);
    this.sessions = new SessionsEndpoint(this.api);
    this.tasks = new TasksEndpoint(this.api);
    this.config = new ConfigEndpoint(this.api);
    this.partners = new PartnersEndpoint(this.api);
    this.stats = new StatsEndpoint(this.api);
    this.assignments = new AssignmentsEndpoint(this.api);
    this.feedback = new FeedbackEndpoint(currentUserId, this.api);
    this.autocomplete = new AutocompleteEndpoint(this.api);
    this.search = new SearchEndpoint(this.api);
    this.countries = new CountriesEndpoint(this.api);
    this.hhg = new AssignmentHhgEndpoint(this.api);
    this.assignee = new AssigneeEndpoint(this.api);
    this.assignmentAnalysis = new AssignmentAnalysisEndpoint(this.api);
    this.assignmentAssignee = new AssignmentAssigneeEndpoint(this.api);
    this.assignmentParty = new AssignmentPartyEndpoint(this.api);
    this.eventsCalendar = new EventsCalendarEndpoint(this.api);
    this.agreeTerms = new AgreeTermsEndpoint(this.api);
    this.realEstate = new AssignmentRealEstateEndpoint(this.api);
    this.visaImmigration = new ImmigrationEndpoint(this.api);
  }

  ping = () => this.api.get(`/ping`).toPromise();

  toApiUrl = (path: string) => this.api.toUrl(path);
}
