import { ApiClient } from '@k2/common/backend/api-client';
import { Client, Document, RegionType } from '@k2/common/entities-state/types';
import { RemoteMutatedFile } from '@k2/common/file-jet/file';
import { FormSpec } from '@k2/common/k2-forms-state/types';
import { extractContinentRegions } from '@k2/common/misc-transformations';
import { pick, values } from 'ramda';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

export class ClientsEndpoint {
  constructor(private api: ApiClient) {}

  fetchAllClients = () => {
    return this.api.get(`/clients`).pipe(map(payload => payload.clients));
  };

  fetchNewClientForm = () => {
    return this.api.get(`/form/client.form`).pipe(
      map(payload => payload.form),
      map(form => ({
        ...form,
        fields: pick(
          ['name', 'short_name', 'countries_id', 'k2_offices_id', 'account_director'],
          form.fields
        )
      }))
    );
  };

  createClient = (data: any): Observable<number> => {
    return this.api.post(`/clients`, data).pipe(map(payload => payload.id));
  };

  fetchClient = (clientId: number): Observable<Client> => {
    return this.api.get(`/clients/${clientId}/summary`).pipe(map(payload => payload.summary));
  };

  fetchClientForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/details`).pipe(map(payload => payload.form));
  };

  updateClient = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/details`, data);
  };

  fetchPopulation = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/population`).pipe(map(payload => payload.population));
  };

  fetchPackages = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/packages`);
  };

  fetchNewPackageForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/packages/form`).pipe(transformToPackageForm);
  };

  createPackage = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/packages`, data);
  };

  fetchPackageForm = (clientId: number, packageId: number) => {
    return this.api.get(`/clients/${clientId}/packages/${packageId}`).pipe(transformToPackageForm);
  };

  updatePackage = (clientId: number, packageId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/packages/${packageId}`, data);
  };

  deletePackage = (clientId: number, packageId: number) => {
    return this.api.delete(`/clients/${clientId}/packages/${packageId}`);
  };

  fetchAddresses = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/addresses`).pipe(map(payload => payload.addresses));
  };

  fetchNewAddressForm = () => {
    return this.api.get(`/form/client.address.form`).pipe(map(payload => payload.form));
  };

  createAddress = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/addresses`, data);
  };

  fetchAddressForm = (clientId: number, addressId: number) => {
    return this.api
      .get(`/clients/${clientId}/addresses/${addressId}`)
      .pipe(map(payload => payload.address));
  };

  updateAddress = (clientId: number, addressId: number, data: any) => {
    return this.api
      .patch(`/clients/${clientId}/addresses/${addressId}`, data)
      .pipe(map(payload => payload.address));
  };

  deleteAddress = (clientId: number, addressId: number) => {
    return this.api.delete(`/clients/${clientId}/addresses/${addressId}`);
  };

  fetchAssignees = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/users`).pipe(map(payload => values(payload.users)));
  };

  fetchStructure = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/structure`).pipe(map(payload => payload.structure));
  };

  fetchSubsidiaries = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/subsidiaries`)
      .pipe(map(payload => payload.subsidiaries));
  };

  fetchNewSubsidiaryForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/subsidiaries/new`).pipe(map(payload => payload.form));
  };

  createSubsidiary = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/subsidiaries`, data);
  };

  fetchSubsidiaryForm = (clientId: number, subsidiaryId: number) => {
    return this.api
      .get(`/clients/${clientId}/subsidiaries/${subsidiaryId}`)
      .pipe(map(payload => payload.subsidiary));
  };

  updateSubsidiary = (clientId: number, subsidiaryId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/subsidiaries/${subsidiaryId}`, data);
  };

  deleteSubsidiary = (clientId: number, subsidiaryId: number) => {
    return this.api.delete(`/clients/${clientId}/subsidiaries/${subsidiaryId}`);
  };

  fetchTeam = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/team`).pipe(map(payload => payload.team));
  };

  fetchAdmins = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/admins`).pipe(map(payload => payload.users));
  };

  fetchNewAdminForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/admins/new`).pipe(map(payload => payload.form));
  };

  createAdmin = (clientId: number, data) => {
    return this.api.post(`/clients/${clientId}/admins`, data);
  };

  fetchAdminForm = (clientId: number, adminId: number) => {
    return this.api.get(`/clients/${clientId}/admins/${adminId}`);
  };

  updateAdmin = (clientId: number, adminId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/admins/${adminId}`, data);
  };

  deleteAdmin = (clientId: number, adminId: number) => {
    return this.api.delete(`/clients/${clientId}/admins/${adminId}`);
  };

  fetchAdminPasswordResetForm = (clientId: number, recipient: string) => {
    return this.api
      .get(`/clients/${clientId}/admins/reset/email/${recipient}`)
      .pipe(map(payload => payload.form));
  };

  postAdminPasswordResetEmail = (clientId: number, data) => {
    return this.api.post(`/clients/${clientId}/admins/reset/email`, data);
  };

  updateAdminPassword = (
    clientId: number,
    adminId: number,
    oldPassword: string,
    newPassword: string
  ) => {
    const data = { old_password: oldPassword, password: newPassword };
    return this.api.patch(`/clients/${clientId}/admins/${adminId}/credentials`, data);
  };

  fetchAdminWelcomeForm = (clientId: number, recipient: string) => {
    return this.api
      .get(`/clients/${clientId}/admins/welcome/email/${recipient}`)
      .pipe(map(payload => payload.form));
  };

  postAdminWelcomeEmail = (clientId: number, data) => {
    return this.api.post(`/clients/${clientId}/admins/welcome/email`, data);
  };

  updateAdminAvatar = (clientId: number, adminId: number, avatar: RemoteMutatedFile) => {
    return this.api.patch(`/clients/${clientId}/admins/${adminId}/avatar`, avatar);
  };

  deleteAdminAvatar = (clientId: number, adminId: number) => {
    return this.api.delete(`/clients/${clientId}/admins/${adminId}/avatar`);
  };

  fetchBillingConfig = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/setup/billing/config`);
  };

  updateBillingConfig = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/setup/billing/config`, data);
  };

  updateBillingHhgConfig = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/setup/billing/hhg/config`, data);
  };

  updateBillingHhgInsuranceConfig = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/setup/billing/hhg/insurance/config`, data);
  };

  fetchInvoicingAddressCandidates = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/setup/billing/invoice/address/candidates`)
      .pipe(map(payload => payload.invoice_addresses));
  };

  addInvoicingAddress = (clientId: number, data: any) => {
    return this.api.put(`/clients/${clientId}/setup/billing/invoice/addresses`, data);
  };

  deleteInvoicingAddress = (clientId: number, addressId: number) => {
    return this.api.delete(`/clients/${clientId}/setup/billing/invoice/addresses/${addressId}`);
  };

  fetchPricing = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/pricing`);
  };

  setClientPricing = (clientId: number, data: any) => {
    return this.api.put(`/clients/${clientId}/pricing`, data);
  };

  fetchRegionsByType = (clientId: number, type: RegionType) => {
    return this.api.get(`/clients/${clientId}/regions/${type}`);
  };

  updateRegionsByType = (clientId: number, type: RegionType, data: any) => {
    return this.api.put(`/clients/${clientId}/regions/${type}`, data);
  };

  fetchRegions = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/countries`);
  };

  updateRegions = (clientId: number, data: any) => {
    return this.api.put(`/clients/${clientId}/countries`, data);
  };

  fetchServices = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/services`);
  };

  updateServices = (clientId: number, data: any) => {
    return this.api.put(`/clients/${clientId}/services`, data);
  };

  fetchRecentAssignments = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignments/recent`)
      .pipe(map(payload => payload.assignments));
  };

  fetchExternallyInitiatedAssignments = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignments/imports`)
      .pipe(map(payload => payload.pending_imports));
  };

  deleteExternallyInitiatedAssignment = (clientId: number, assignmentId: number) => {
    return this.api.delete(`/clients/${clientId}/assignments/imports/${assignmentId}`);
  };

  fetchK2ApiAssignmentInitiations = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignments/k2imports`)
      .pipe(map(payload => payload.pending_imports));
  };

  fetchWorkspaceImmigrations = (clientId: number) => {
    return this.api
      .get(`/assignments/clients/${clientId}/immigration-requests`)
      .pipe(map(payload => payload.requests));
  }

  deleteWorkspaceImmigrationRequests = (clientId: number, immigrationRequestId: number) => {
    return this.api.delete(`/assignments/clients/${clientId}/immigration-requests/${immigrationRequestId}`);
  }

  fetchBriefForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/brief`).pipe(map(payload => payload.form));
  };

  updateBrief = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/brief`, data);
  };

  fetchCustomFields = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/customfields`).pipe(map(payload => payload.fields));
  };

  fetchNewCustomFieldForm = () => {
    return this.api.get(`/form/custom.field.form`).pipe(map(payload => payload.form));
  };

  createCustomField = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/customfields`, data);
  };

  fetchCustomFieldForm = (clientId: number, fieldId: number) => {
    return this.api
      .get(`/clients/${clientId}/customfields/${fieldId}`)
      .pipe(map(payload => payload.form));
  };

  updateCustomField = (clientId: number, fieldId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/customfields/${fieldId}`, data);
  };

  deleteCustomField = (clientId: number, fieldId: number) => {
    return this.api.delete(`/clients/${clientId}/customfields/${fieldId}`);
  };

  fetchFixedFields = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/fixed/customfields`)
      .pipe(map(payload => payload.form));
  };

  updateFixedFields = (clientId: number, data: any) => {
    return this.api
      .patch(`/clients/${clientId}/fixed/customfields`, data)
      .pipe(map(payload => payload.form));
  };

  updateLogo = (clientId: number, logo: RemoteMutatedFile | null) => {
    if (logo != null) {
      return this.api.patch(`/clients/${clientId}/customise`, { logo });
    }
    return this.api.delete(`/clients/${clientId}/customise/logo`);
  };

  fetchDocuments = (clientId: number): Observable<Document[]> => {
    return this.api.get(`/clients/${clientId}/documents`).pipe(map(payload => payload.documents));
  };

  fetchNewDocumentForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/documents/new`).pipe(map(payload => payload.form));
  };

  createDocument = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/documents`, data);
  };

  fetchDocumentForm = (clientId: number, documentId: number) => {
    return this.api
      .get(`/clients/${clientId}/documents/${documentId}`)
      .pipe(map(payload => payload.form));
  };

  updateDocument = (clientId: number, documentId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/documents/${documentId}`, data);
  };

  deleteDocument = (clientId: number, documentId: number) => {
    return this.api.delete(`/clients/${clientId}/documents/${documentId}`);
  };

  fetchHhgAllowances = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/setup/hhg/allowances`)
      .pipe(map(payload => payload.allowances));
  };

  fetchNewHhgAllowanceForm = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/setup/hhg/allowances/new`)
      .pipe(map(payload => payload.form));
  };

  createHhgAllowance = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/setup/hhg/allowances`, data);
  };

  fetchHhgAllowance = (clientId: number, allowanceId: number) => {
    return this.api
      .get(`/clients/${clientId}/setup/hhg/allowances/${allowanceId}`)
      .pipe(map(payload => payload.form));
  };

  updateHhgAllowance = (clientId: number, allowanceId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/setup/hhg/allowances/${allowanceId}`, data);
  };

  deleteHhgAllowance = (clientId: number, allowanceId: number) => {
    return this.api.delete(`/clients/${clientId}/setup/hhg/allowances/${allowanceId}`);
  };

  fetchAdminAccessTypeForm = (clientId: number, userId: number) => {
    return this.api
      .get(`/clients/${clientId}/admins/${userId}/access-type`)
      .pipe(map(payload => payload.form));
  };

  patchAdminAccessType = (clientId: number, userId: number, data) => {
    return this.api
      .patch(`/clients/${clientId}/admins/${userId}/access-type`, data)
      .pipe(map(payload => payload.form));
  };

  fetchAdminPermissionsForm = (clientId: number, userId: number) => {
    return this.api
      .get(`/clients/${clientId}/admins/${userId}/permissions`)
      .pipe(map(payload => payload.sso_permissions_form));
  };

  patchAdminPermissions = (clientId: number, userId: number, data) => {
    return this.api
      .patch(`/clients/${clientId}/admins/${userId}/permissions`, data)
      .pipe(map(payload => payload.form));
  };

  fetchAdminSecurityForm = (clientId: number, userId: number) => {
    return this.api
      .get(`/clients/${clientId}/admins/${userId}/security-form`)
      .pipe(map(payload => payload.form));
  };

  patchAdminSecurity = (clientId: number, userId: number, data) => {
    return this.api
      .patch(`/clients/${clientId}/admins/${userId}/security-form`, data)
      .pipe(map(payload => payload.form));
  };

  fetchAssigneeSessionHistory = (clientId: number, assigneeId: number) => {
    return this.api.get(`/clients/${clientId}/assignee/${assigneeId}/session/history`);
  };

  fetchClientSSOConfigSetup = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/setup/authentication/sso/setup`);
  };

  updateClientSSOConfigSetup = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/setup/authentication/sso/setup`, data);
  };

  runClientSSOTest = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/setup/authentication/sso/saml/test`);
  };

  fetchClientAdminSSOConfigSetup = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/setup/authentication/sso/setup`);
  };

  updateClientAdminSSOConfigSetup = (clientId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/setup/authentication/sso/setup`, data);
  };

  runClientAdminSSOTest = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/setup/authentication/sso/saml/test`);
  };

  fetchAssigneeWelcomeForm = (clientId: number, assignmentId: number, recipient: string) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/welcome/email/${recipient}`
      )
      .pipe(map(payload => payload.form));
  };

  postAssigneeWelcomeEmail = (clientId: number, assignmentId: number, data) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/welcome/email`,
      data
    );
  };

  fetchAssigneePasswordResetForm = (clientId: number, assignmentId: number, recipient: string) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/reset/email/${recipient}`
      )
      .pipe(map(payload => payload.form));
  };

  postAssigneePasswordResetEmail = (clientId: number, assignmentId: number, data) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/reset/email`,
      data
    );
  };

  getEquusConfigForm = (clientId: number) => {
    return this.api
      .get(`/equus/settings/${clientId}`)
      .pipe(map(payload => payload.clientEquus.fields));
  };

  getPseudonymisationConfigForm = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/details`)
      .pipe(map(payload => payload.form.fields));
  };

  patchPseudonymisationConfig = (clientId: number, data) => {
    return this.api.patch(`/clients/${clientId}/config`, data).pipe(take(1));
  };

  generateEncryptionKey = (clientId: number) => {
    return this.api.patch(`/clients/${clientId}/generate-encryption-key`, {})
      .pipe(map(payload => payload.form.fields));
  }

  getMoveAssistApiForm = (clientId: number) => {
    return this.api
      .get(`/move/assist/settings/${clientId}`)
      .pipe(map(payload => payload.clientMoveAssist.fields));
  };

  patchEquusConfig = (clientId: number, data) => {
    return this.api.patch(`/equus/settings/${clientId}`, data).pipe(take(1));
  };

  patchMoveAssistApiConfig = (clientId: number, data) => {
    return this.api.patch(`/move/assist/settings/${clientId}`, data).pipe(take(1));
  };

  testEquusConnection = data => {
    return this.api.post(`/equus/settings/test-equus-connection`, data).pipe(
      take(1),
      map(payload => payload.testConnectionResult)
    );
  };

  testMoveAssistApiConnection = data => {
    return this.api.post(`/move/assist/settings/test-move-assist-connection`, data).pipe(
      take(1),
      map(payload => payload.testConnectionResult)
    );
  };

  getSharePointConfigForm = (clientId: number) => {
    return this.api
      .get(`/sharepoint/settings/${clientId}`)
      .pipe(map(payload => payload.clientSharePoint.fields));
  };

  patchSharePointConfig = (clientId: number, data) => {
    return this.api.patch(`/sharepoint/settings/${clientId}`, data).pipe(take(1));
  };

  testSharePointConnection = data => {
    return this.api.post(`/sharepoint/settings/test-sharepoint-connection`, data).pipe(
      take(1),
      map(payload => payload.testConnectionResult)
    );
  };

  getClientAccountManager(id: number) {
    return this.api.get(`/clients/${id}/summary`).pipe(take(1), map(({ summary }) => summary.account_director));
  }

  postRelocityIntegration = (id: number, data: any) => {
    return this.api.post(`/clients/${id}/Relocity/integration`, data);
  }

  getRelocityIntegration(id: number) {
    return this.api.get(`/clients/${id}/Relocity/integration`);
  }

  fetchVisaSponsors = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/sponsors`)
      .pipe(map(payload => payload.sponsors));
  };

  fetchNewVisaSponsorForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/sponsors/new`).pipe(map(payload => payload.form));
  };

  fetchVisaSponsorForm = (clientId: number, sponsorId: number) => {
    return this.api
      .get(`/clients/${clientId}/sponsors/${sponsorId}`)
      .pipe(map(payload => payload.sponsor));
  };

  createVisaSponsor = (clientId: number, data: any) => {
    return this.api.post(`/clients/${clientId}/sponsors`, data);
  };

  deleteVisaSponsor = (clientId: number, sponsorId: number) => {
    return this.api.delete(`/clients/${clientId}/sponsors/${sponsorId}`);
  };

  updateVisaSponsor = (clientId: number, sponsorId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/sponsors/${sponsorId}`, data);
  };
}

function transformToPackageForm(payload: Observable<any>): Observable<FormSpec> {
  return payload.pipe(
    tap(payload => {
      payload.form.fields.origin_countries.attributes.valueOptions = extractContinentRegions(
        payload.countries.origin_countries
      );

      payload.form.fields.destination_countries.attributes.valueOptions = extractContinentRegions(
        payload.countries.destination_countries
      );
    }),
    map(payload => payload.form)
  );
}
