import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { K2User } from '@k2/common/entities-state/types';

@Component({
  selector: 'k2-user-assignments-ingot',
  templateUrl: 'k2-user-assignments-ingot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2UserAssignmentsIngotComponent {
  @Input() user: K2User;

  get numberOfActiveAssignments(): number {
    return this.user.assignment_stats.active;
  }

  get numberOfOngoingAssignments(): number {
    return this.user.assignment_stats.ongoing;
  }
}
